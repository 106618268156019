<template>
  <div class="container">
    <div class="divide"></div>
    <div class="title">
      <div style="width: 50%;">
        <img style="width: 100%;" src="../assets/cases/whgg.png" alt="">
      </div>
      <!-- <div v-if="isPause" class="videos">
        <div  v-for="(item, i) in arrayWithIndex.slice(startVideo, endVideo)" :key="i">
          <img
            :key="i"
            v-if="item.index == indexVideo"
            class="bg"
            :src="item.item.path"
            alt=""
          />
          <img
            @click="playVideo(item.index)"
           :key="i + 100"
            class="btn"
            src="../assets/index/bf.png"
            alt=""
            />
        </div>
        <div class="cases-video">
          <div class="nav-bar">
            <div class="img-list">
              <div
                v-for="(item, i) in arrayWithIndex.slice(startVideo, endVideo)"
                :key="i"
                @click="toggleVideo(item.index)"
                class="img-shell"
              >
                <img
                  :class="item.index == indexVideo ? 'top-img actived' : 'top-img'"
                  :src="item.item.path"
                  alt=""
                />
              </div>
              <img
                @click="backVideo"
                class="left"
                src="../assets/hall/jt1.png"
                alt=""
              />
              <img
                @click="forwardVideo"
                class="right"
                src="../assets/hall/jt2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="videos">
        <video
          width="100%"
          height="100%"
          controls="controls"
          autoplay="autoplay"
          loop
          :src="vidoSrc"
        >
        </video>
        <div class="backBtn" @click="backVideoSrc">返回</div>
      </div> -->
      <div class="text">
        <div>中国光谷·数字经济产业园</div>
        <div>
          <span style="display: inline-block; width: 0.33rem"></span>
          中国光谷·数字经济产业园项目西地块一期工程总承包（EPC)项目，建设地点为湖北省
          武汉市东湖新技术开发区高新大道与同力路交叉口以东南。本工程由7栋厂房，一个室外大平台和一个大型地下室组成。
          建筑面积:147059.14m'，地下室:31633.17m，架空层:9478.64m。其中A1#为高层厂房，
          地上17层;A2#为高层厂房，地上21层;A3#为多层厂房，地上3层;A4#为高层厂房，地上16层;
          A5#、A6#、A7#为多层厂房，室外大平台下部为架空层及局部设备用房;大型地下室为地下汽车库及配套设备用房。
        </div>
      </div>
    </div>
    <div class="case">
      <div class="title">客户工程案例</div>
      <div class="line"></div>
      <div class="en-title">Customer Engineering Cases</div>
      <div class="cases-content">
        <div class="nav-bar">
          <div class="img-list">
            <div
              v-for="(item, i) in arrayWithIndex.slice(start, end)"
              :key="i"
              @click="toggleScene(i)"
            >
              <img class="top-img" :src="item.item.projectImage" alt="" />
              <div
                :class="item.index === index ? 'img-title title-actived' : 'img-title'"
              >
                <div class="intro-title">{{ item.item.projectName }}</div>
                <div class="intro-content">{{ item.item.projectDescription }}</div>
                <div class="intro-enter">
                  <div :class="item.index==index?'btnactive':''" @click="viewCase(item.item)">阅读他们的案例 ></div>
                </div>
              </div>
            </div>
            <div class="arrow left" @click="back">
              <span></span>
            </div>
            <div class="arrow right" @click="forward">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function() {
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      cases: [
        {
          title: "客户案例标题",
          content:
            "操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操",
        },
      ],
      isPause: true,
      indexVideo: 0,
      startVideo: 0,
      endVideo: 4,
      index: 0,
      start: 0,
      end: 3,
      videoList: [
        {
          path: require("../assets/cases/t15.png"),
          video:'https://oss.zijingshuke.com/1706596249018.mp4'
        },
        {
          path: require("../assets/cases/t10.png"),
          video:'https://oss.zijingshuke.com/1706596249018.mp4'
        },
        {
          path: require("../assets/cases/t11.png"),
          video:'https://oss.zijingshuke.com/1706596249018.mp4'
        },
        {
          path: require("../assets/cases/t15.png"),
          video:'https://oss.zijingshuke.com/1706596249018.mp4'
        },
        {
          path: require("../assets/cases/t11.png"),
          video:'https://oss.zijingshuke.com/1706596249018.mp4'
        },
        {
          path: require("../assets/cases/t10.png"),
          video:'https://oss.zijingshuke.com/1706596249018.mp4'
        },
      ],
      vidoSrc:'',
      imgList: [
        {
          path: require("../assets/cases/t10.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content:
              "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
        {
          path: require("../assets/cases/t11.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content:
              "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
        {
          path: require("../assets/cases/t112.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content:
              "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
        {
          path: require("../assets/hall/cq4.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content: "项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
      ],
      // 工程案例数组
      projectList:[]
    };
  },
  computed: {
    arrayWithIndex() {
      return this.projectList.map((item, index) => {
        return { index, item };
      });
    },
  },

  methods: {
    // 请求客户案例列表
    requestCustomList(){
      let param ={
        pageNum: 1,
        pageSize:100,
      }
      this.$post("/web/cooperationCase/pageList", param)
        .then((res) => {
          // 主要参数
          if (res.code == 1) {
            // console.log(res.data);
            this.projectList= res.data.list;
          } else {
          console.log("异常");
          }
        });
    },
    toggleScene(i) {
      this.index = i;
    },
    toggleVideo(i) {
      this.indexVideo = i;
      console.log(i)
      this.vidoSrc=this.videoList[this.indexVideo].video
    },
    // 视频返回
    backVideoSrc(){
      this.isPause = true;  
      console.log(this.isPause)               
    },
    // 播放视频
    playVideo(playIndex) {
      this.isPause = false;
      console.log(playIndex);
      this.vidoSrc=this.videoList[this.indexVideo].video
      setTimeout(() => {
        this.initVideo();
      });
    },
    // 视频下一个
    forwardVideo() {
      if (this.indexVideo < 3) {
        this.indexVideo++;
      } else {
        if (this.endVideo < this.videoList.length) {
          this.startVideo++;
          this.endVideo++;
          this.indexVideo++;
          // this.indexVideo = 3
        }
      }
      this.vidoSrc=this.videoList[this.indexVideo].video
      console.log('start'+this.startVideo)
      console.log('end'+this.endVideo)
      console.log(this.indexVideo)
    },
    // 视频上一个
    backVideo() {
      if (this.indexVideo > 0) {
        if (this.startVideo > 0) {
          this.startVideo--;
          this.endVideo--;
          this.indexVideo--;
        } else {
          this.indexVideo--;
        }
      }
      this.vidoSrc=this.videoList[this.indexVideo].video
      console.log(this.indexVideo)
    },
    // 客户案例下一个
    forward() {
      if (this.index < 2) {
        this.index++;
      } else {
        console.log(this.projectList.length)
        if (this.end < this.projectList.length) {
          this.start++;
          this.end++;
          this.index++;
        }
      }
      console.log('start:'+this.start)
      console.log('end:'+this.end)
    },
    // 客户案例上一个
    back() {
      if (this.index > 0) {
        if (this.start > 0) {
          this.start--;
          this.end--;
          this.index--;
        } else {
          this.index--;
        }
        console.log('start:'+this.start)
      console.log('end:'+this.end)
      }
    },
    initVideo() {
      const video = document.querySelector("video");
      console.log("====", video);

      video &&
        video.addEventListener("ended", () => {
          console.log("end.");
        });
    },
    // 跳转到客户案例详情
    viewCase(item) {
      this.$router.push('/custom');
      localStorage.setItem('customDetail',JSON.stringify(item))
    },
  },
  mounted() {
    this.requestCustomList()
  },
};
</script>

<style scoped>
.container > div {
  margin: 0 1.6rem;
}
.container .divide {
  height: 0.8rem;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  margin: 0;
}
.container > .title {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-top: .78rem;
  margin-bottom: 1.02rem;
}
.videos {
  position: relative;
  width: 9.28rem;
  height: 5.7rem;
}
.videos .bg {
  width: 9.28rem;
  height: 100%;
}
.videos .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.9rem;
  height: 0.9rem;
}
/* 返回按钮 */
.videos .backBtn{
  position: absolute;
  top: 0%;
  left: 0%;
  border-radius: 4px;
  background: #127bf2;
  color: #fff;
  font-size: 0.25rem;
  padding: 0.08rem;
  box-sizing: border-box;
  cursor: pointer;
}
.cases-video {
  position: absolute;
  bottom: 0.21rem;
  width: 100%;
  height: 0.86rem;
}
.cases-video .nav-bar {
  height: 100%;
}
.text {
  width: 40vw;
  margin-left: 0.77rem;
}
.text > div:nth-of-type(1) {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 0.32rem;
  line-height: 24px;
  font-weight: 500;
  color: #272727;
  margin-bottom: 0.28rem;
  cursor: pointer;
}
.text > div:nth-of-type(2) {
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #a0a0a0;
  line-height: 36px;
  cursor: pointer;
}
.case .title {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #272727;
  cursor: pointer;
}
.case .en-title {
  font-size: 0.26rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #909090;
  margin-bottom: 0.58rem;
  cursor: pointer;
}
.line {
  width: 2.5vw;
  height: 3px;
  background: #127bf2;
  border-radius: 2px;
  margin: 10px 0;
}

.cases-content {
  position: relative;
  height: 5.68rem;
  margin-bottom: 1rem;
}
.nav-bar {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .cases-content .nav-bar {
    height: 100%;
} */
.img-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 85%;
  height: 100%;
  overflow: hidden;
}
.img-list > div {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 4.4rem;
  height: 100%;
  display: flex;
}
.img-list > .img-shell {
  width: 1.7rem;
  height: 0.86rem;
  padding-right: 0.2rem;
  position: relative;
}
.img-list > .img-shell .top-img {
  height: 100%;
  filter: brightness(0.5);
}
.img-list > .img-shell .actived {
  filter: brightness(1);
}
.top-img {
  width: 100%;
  height: 50%;
}
.img-title {
  width: 100%;
  height: 50%;
  text-align: center;
  line-height: 220%;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  border: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.img-title:hover {
  color: #fff;
  background-color: #127bf2;
}
.title-actived {
  color: #fff;
  background-color: #127bf2;
}
.title-actived .intro-content {
  color: #fff;
}
.img-list .arrow {
  width: 0.56rem;
  height: 5.68rem;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  border: 1px solid #e7e7e7;
}
.cases-content .left {
  left: 0;
}
.cases-content .right {
  right: 0;
}
.left {
  position: absolute;
  left: 1.5%;
  top: 50%;
  transform: translateY(-50%);
}
.cases-video .left,
.cases-video .right {
  width: 0.4rem;
  height: 0.5rem;
}
.right {
  position: absolute;
  right: 1.5%;
  top: 50%;
  transform: translateY(-50%);
}
.arrow span {
  display: inline-block;
  border-top: 0.18rem solid transparent;
  border-bottom: 0.18rem solid transparent;
  border-left: 0.26rem solid transparent;
  border-right: 0.26rem solid #127bf2;
  position: absolute;
  right: 0.15rem;
}
.right span {
  display: inline-block;
  border-top: 0.18rem solid transparent;
  border-bottom: 0.18rem solid transparent;
  border-left: 0.26rem solid #127bf2;
  border-right: 0.26rem solid transparent;
  left: 0.15rem;
}
.intro-title {
  font-size: 0.23rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  margin: 0.3rem 0 0.16rem 0;
}
.intro-content {
  font-size: 0.16rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bbbbbb;
  line-height: 0.35rem;
  margin: 0 0.37rem;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
  height: 0.7rem;
  margin-bottom: 0.2rem;
}
.intro-enter > div {
  width: 3.66rem;
  height: 0.52rem;
  background: #127bf2;
  border-radius: 0.26rem;
  line-height: 0.52rem;
  color: #fff;
}
.intro-enter > div:hover {
  background: #3692fc;
}
.intro-enter .btnactive{
    background: #3692fc; 
}
</style>
